import React from "react"

import { withTitle } from "./withTitle"
import { StyledTitle, BackLink, Children, Spacer } from "./Title.styled"
import { Icon } from "../Icon/Icon"

export const Title = withTitle(
  ({ backLink, children }: Props): JSX.Element => (
    <StyledTitle>
      {backLink ? (
        <BackLink to={backLink}>
          <Icon name={"chevronLeft"} size={"s"} />
        </BackLink>
      ) : null}
      <Children>{children}</Children>
      {backLink ? <Spacer /> : null}
    </StyledTitle>
  )
)

export interface Props {
  children?: any
  backLink?: any
}
