import React, { useEffect, useMemo } from "react"
import { useStripe } from "../../../hooks/useStripe"

export const withPlansPopup = Component =>
  React.memo(({ activePopup, handlePopup, ...props }) => {
    const { membership, subscription } = { ...props }
    const { plans, changeMembership, loading } = useStripe()
    const currentMembership = plans[subscription && subscription[0].plan.nickname.toLowerCase()]
    const targetMembership = membership && membership[0]

    const text1 = `You are ${
      currentMembership?.product?.plan?.amount > targetMembership?.product?.plan?.amount ? "downgrading" : "upgrading"
    } your membership plan from`
    const text2 = `${currentMembership?.product.plan.nickname} to ${targetMembership?.product.plan.nickname} at $${
      targetMembership?.product.plan.amount / 100
    } per month`

    const updateMembership = () => {
      changeMembership(subscription?.[0].id, targetMembership?.product.plan.id)
    }

    useEffect(() => {
      if (loading == false) {
        handlePopup(false)
      }
    }, [loading])

    return useMemo(
      () => (
        <Component
          activePopup={activePopup}
          handlePopup={handlePopup}
          changeMembership={updateMembership}
          loading={loading}
          text1={text1}
          text2={text2}
        />
      ),
      [activePopup, loading]
    )
  })
