import { StyledPopupButton, StyledPopupP, StyledPopupTitle } from "../../../styled/Popup"
import { Icon } from "../../Icon/Icon"
import { Popup } from "../../Popup/Popup"
import { Close } from "../../Popup/Popup.styled"
import { withPlansPopup } from "./withPlansPopup"
import { Button } from "./PlansPopup.styled"

export const PlansPopup = withPlansPopup(
  ({ activePopup, text1, text2, loading, handlePopup, changeMembership }: Props): JSX.Element => (
    <Popup active={activePopup} setActive={handlePopup}>
      <Close type={"button"} onClick={() => handlePopup(false)}>
        <Icon name={"cross"} size={"s"} colour={"tertiary"} />
      </Close>
      <StyledPopupTitle>Change Plan</StyledPopupTitle>
      <StyledPopupP>{text1} </StyledPopupP>
      <StyledPopupP>{text2}</StyledPopupP>
      <Button colour={`dark`} size={`large`} theme={`primary`} onClick={changeMembership}>
        {loading ? "Processing..." : "Confirm"}
      </Button>
    </Popup>
  )
)

export interface Props {
  activePopup?: boolean
  text1?: string
  text2?: string
  loading?: boolean
  handlePopup?: (show: boolean) => void
  changeMembership?: () => void
}
