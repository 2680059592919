import React from "react"

import { ListChecklist } from "../Lists/ListChecklist"

import {
  Column,
  Container,
  Responsive,
  Row,
  Card,
  Frame,
  Title,
  Price,
  PriceSup,
  Subtitle,
  Content,
  Coupon,
  Error,
  ButtonLink,
} from "./CardMembership.styled"

import { Button } from "../Button/Button"

export const CardMembership = ({
  items = [],
  title,
  titleMobile,
  price = 0,
  currency,
  content,
  subcontent,
  coupon,
  error,
  buttonLink,
  buttonText,
  loading,
  disabled,
  onClick = () => false,
}: Props): JSX.Element => {
  const itemSplit = Math.floor(items.length / 2)

  return (
    <Card size={"full"}>
      <Frame>
        <Container width={`lg`}>
          <Responsive screen={`<md`}>
            <Title>{titleMobile}</Title>
          </Responsive>
          <Responsive screen={`md>`}>
            <Title>{title}</Title>
          </Responsive>
          {price ? (
            <>
              <Price>
                <PriceSup>$</PriceSup>
                {price}
              </Price>
              <Subtitle pink>{currency} per month</Subtitle>
            </>
          ) : null}
          <Responsive screen={`<md`}>
            <Content>{content}</Content>
          </Responsive>

          <Responsive screen={`md>`}>
            <Row>
              <Column position={`right`} width={`md:w-1/2`}>
                <ListChecklist items={items.slice(0, itemSplit)} simple />
              </Column>
              <Column position={`left`} width={`md:w-1/2`}>
                <ListChecklist items={items.slice(itemSplit)} simple />
              </Column>
            </Row>
          </Responsive>
          <Responsive screen={`<md`}>
            <ListChecklist items={items} />
          </Responsive>

          <ButtonLink to={buttonLink ? buttonLink : null} onClick={e => !buttonLink && e.preventDefault()}>
            <Button disabled={disabled} size={"large"} theme={"primary"} colour={"dark"} loading={loading} onClick={onClick}>
              {buttonText}
            </Button>
          </ButtonLink>

          {price ? (
            <Responsive screen={`<md`}>
              <Subtitle>
                ${price} {currency} per month
              </Subtitle>
            </Responsive>
          ) : null}
          {error && <Error>{error}</Error>}
          {coupon && !error && <Coupon>Coupon code: {coupon}</Coupon>}
          <Content>{subcontent}</Content>
        </Container>
      </Frame>
    </Card>
  )
}

export interface Props {
  title: string
  titleMobile?: string
  price: number
  currency: string
  content: string
  items: Array<string>
  subcontent?: string
  coupon?: string
  error?: string
  buttonText?: string
  buttonLink?: string
  loading?: boolean
  disabled: boolean
  onClick?: () => void
}
