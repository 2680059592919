import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { withMember } from "../../hoc/withMember"
import { AccountPlans as Page } from "../../components/Customer/Plans/Plans"

export const query = graphql`
  query {
    page: sanityPageAccountPlans {
      title
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
    }
    memberships: allSanityMembership {
      edges {
        node {
          ...GatsbyMembershipFragment
        }
      }
    }
  }
`

export default withAuthentication(withMember(({ data, ...props }) => <Page {...props} {...data} />))
