import tw, { styled } from "twin.macro"

import { P } from "../../styled/Text"

export const List = styled.ul`
  ${tw`my-8`}
  ${({ simple }) => (simple ? tw`inline-flex flex-col justify-center text-center` : tw`block border-t border-grey-pale`)}
`
export const Item = styled.li`
  ${tw`items-center text-left`}
  ${({ simple }) => (simple ? tw`inline-flex flex-initial py-1 px-2 md:py-2 md:px-4` : tw`flex py-3 px-2 border-b border-grey-pale`)}
`
export const Title = styled(P)`
  ${({ simple }) => (simple ? tw`text-base ml-4 font-medium md:ml-8` : tw`text-sm ml-8 md:text-lg`)}
  ${({ light }) => (light ? tw`text-white` : tw`text-grey-mid`)}
`
