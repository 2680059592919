import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

// import { StyledButton } from "../styled/Button"
import { Button as StyledButton } from "../components/Button/Button"
import { H1, P } from "./Text"

const styles = {
  colour: {
    pink: tw`bg-pink`,
    green: tw`bg-green`,
  },
}

export const StyledStepsMessage = tw.div`text-center my-12`
export const StyledStepsH1: any = styled(H1)`
  ${tw`flex items-start justify-center mb-4 md:mb-2 text-lg md:text-3xl`}
`
export const StyledStepsHeadingInner = tw.span`block ml-10`
export const StyledCloseButton = styled(Link)`
  ${tw`ml-6 mt-2`}
`
export const StyledStepsP = styled(P)`
  ${tw`md:text-lg`}
`
export const StyledStepsBg = styled.div<{
  colour: string
}>`
  ${tw`hidden md:block w-full h-full absolute inset-x-0 top-0`}
  ${({ colour }) => (colour ? styles.colour[colour] : null)}
` //h-1/2
export const StyledStepsButton = styled(StyledButton)<{
  large: boolean
}>`
  ${tw`md:min-w-72 inline-block text-center`}
  ${({ large }) => large && tw`mt-12 md:mt-10`}
`
