import React from "react"

import { Icon } from "../Icon/Icon"
import { List, Item, Title } from "./ListChecklist.styled"

export const ListChecklist = ({ items = [], light = false, simple = false }: Props): JSX.Element => (
  <List simple={simple}>
    {items && items.length
      ? items.map((item, index) => (
          <Item key={index} simple={simple}>
            <Icon name={"check"} size={simple ? "ml" : "m"} colour={"primary"} />
            <Title light={light} simple={simple}>
              {item}
            </Title>
          </Item>
        ))
      : null}
  </List>
)

export interface Props {
  items: Array<string>
  simple?: boolean
  light?: boolean
}
