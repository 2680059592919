import React from "react"

import { withPlans } from "./withPlans"
import { CardMembership } from "../../Cards/CardMembership"
import { Icon } from "../../Icon/Icon"
import { Title } from "../../Title/Title"
import { Container, Responsive, StepsH1, StepsHeadingInner, StepsP, StepsBg, Close, Plans } from "./Plans.styled"
import { PlansPopup } from "./PlansPopup"

const getButtonText = (subscription, membership, subscriptionLoading) => {
  if (subscriptionLoading) return ""
  if (subscription?.length > 0) {
    const { id: currentSubscriptionId, amount: currentSubscriptionAmount } = subscription[0].plan
    const { id: membershipId, amount: membershipAmount } = membership.product.plan
    if (currentSubscriptionId == membershipId) {
      return "Current Plan"
    } else {
      if (currentSubscriptionAmount > membershipAmount) {
        return "Downgrade Plan"
      }
      return "Upgrade Plan"
    }
  }
  return "Subscribe Now"
}

export const AccountPlans = withPlans(
  ({
    title,
    content,
    customer,
    memberships,
    code,
    loading,
    error,
    subscription,
    subscriptionLoading,
    activePopup,
    membership,
    handleCheckout,
    popupChangeMembershipDialog,
    handlePopup,
  }: Props): JSX.Element => (
    <Container collapse={`<md`} width={`xl`}>
      <Responsive screen={`<md`}>
        <Title>
          <StepsH1>
            <StepsHeadingInner>{title}</StepsHeadingInner>
            <Close to={`/account/dashboard`}>
              <Icon name={"cross"} size={"s"} colour={"tertiary"} state={`asblock`} />
            </Close>
          </StepsH1>
          {content && <StepsP>{content}</StepsP>}
        </Title>
      </Responsive>

      <Plans>
        {memberships?.map(membership => (
          <CardMembership
            title={membership?.title}
            titleMobile={membership?.title}
            content={membership?.description}
            subcontent={membership?.subcontent}
            coupon={code}
            items={membership?.benefits}
            price={membership?.product?.plan?.amount / 100}
            currency={membership?.product?.plan?.currency?.toUpperCase() || "AUD"}
            buttonText={getButtonText(subscription, membership, subscriptionLoading)}
            buttonLink={!customer && "/account/register"}
            onClick={() => {
              if (customer) {
                if (subscription?.length > 0) {
                  popupChangeMembershipDialog(membership)
                  handlePopup(true)
                } else {
                  handleCheckout(membership?.product?.plan?.id, membership?.product?.plan?.nickname)
                }
              } else {
                return false
              }
            }}
            loading={loading}
            error={error}
            disabled={membership?.product?.plan?.id == subscription?.[0]?.plan?.id}
          />
        ))}
      </Plans>
      <PlansPopup handlePopup={handlePopup} activePopup={activePopup} subscription={subscription} membership={membership}></PlansPopup>
    </Container>
  )
)

export interface Props {
  title?: string
  content?: any
  customer?: any
  memberships?: Array<any>
  code?: string
  error?: string
  loading?: boolean
  subscription?: any
  subscriptionLoading?: boolean
  activePopup?: boolean
  membership?: any
  handleCheckout?: (price: string, type: string) => void
  popupChangeMembershipDialog?: (currentSubscriptionId?: string, membership?: any) => void
  handlePopup?: (show: boolean) => void
}
