import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

import { H2, H3, H4, P } from "../../styled/Text"
import { StyledButton } from "../../styled/Button"
import { StyledCard } from "../../styled/Card"

export { StyledColumn as Column } from "../../styled/Column"
export { StyledContainer as Container } from "../../styled/Container"
export { StyledResponsive as Responsive } from "../../styled/Responsive"
export { StyledRow as Row } from "../../styled/Row"

export const Card = tw(StyledCard)`p-10 text-center rounded-xxl md:mt-8 mb-8 md:mb-0`
export const Frame = tw.figcaption``
export const Title = tw(H2)`mb-2 text-lg text-grey-dark md:text-3xl`
export const Price = tw(H3)`mb-2 lg:text-5xl text-5xl text-pink font-normal md:font-medium`
export const PriceSup = tw.sup`lg:text-lg text-lg md:inline-block md:mr-2 md:align-middle`
export const Subtitle = styled(H4)`
  ${tw`mb-5 text-md lg:text-md text-grey-dark`}
  ${({ pink }) => pink && tw`md:text-pink`}
`
export const Content = tw(P)`mb-4 text-base text-grey-mid md:text-grey-light md:text-xs`
export const Coupon = tw(P)`mb-3 font-bold text-base text-pink md:text-xs`
export const Error = tw(P)`mb-3 font-bold text-base text-invalid md:text-xs`
export const Button = tw(StyledButton)`my-3 px-16`
export const ButtonLink = tw(Link)`inline-block mb-6 md:mb-2`
