import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useCore } from "../../../hooks/useCore"
import { useApp } from "../../../hooks/useApp"
import { useStripe } from "../../../hooks/useStripe"
import { useKlaviyo } from "../../../hooks/useKlaviyo"

export const withPlans = Component =>
  //@ts-ignore
  React.memo(({ name = "AccountPlans", page, location }) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { customer, setFixed } = useApp()
    const { openCheckout, plans, loading, error, getSubscription, subscription } = useStripe()
    const { trackEvent } = useKlaviyo()
    const [subscriptionLoading, setSubscriptionLoading] = useState(false)

    const params = new URLSearchParams(location?.search)
    const code = params.get("code") || false
    const type = params.get("type") || false

    const title = page?.title
    const content = sanityContent(page?.content)

    const items = Object.keys(plans)?.map(item => ({
      ...plans[item],
      description: sanityContent(plans[item]?.description),
      subcontent: sanityContent(plans[item]?.subcontent),
    }))
    const activeItems = type ? items?.filter(({ slug }) => slug?.current === type) : items

    trackEvent("Viewed Account Plans", items)

    useEffect(() => {
      if (customer?.id) {
        setSubscriptionLoading(true)
        getSubscription(customer?.email)
      }
    }, [customer, loading])

    useEffect(() => {
      if (subscription) {
        setSubscriptionLoading(false)
      }
    }, [subscription])

    const [activePopup, handlePopup] = useState(false)
    const [membership, setMembership] = useState([])

    const openChangeMembershipDialog = (membership?: any) => {
      setMembership([membership])
    }

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          title={title}
          content={content}
          customer={customer?.email?.length}
          memberships={activeItems}
          handleCheckout={openCheckout}
          code={code}
          loading={loading || subscriptionLoading}
          error={error}
          subscription={subscription}
          subscriptionLoading={subscriptionLoading}
          popupChangeMembershipDialog={openChangeMembershipDialog}
          activePopup={activePopup}
          handlePopup={handlePopup}
          membership={membership}
        />
      ),
      [subscription, activePopup, subscriptionLoading]
    )
  })
